import React from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../redux/store";
import beam from "../assets/blaster.mp3";
const Controller = () => {
	const dispatch = useDispatch();
	const beamSound = new Audio(beam);
	beamSound.load();

	let state = useSelector((state) => state.first);

	const handleButtonClicked = (e) => {
		e = e.target.innerHTML;
		if (e === "+/-") e = "-";
		dispatch({
			type: "buttonClick",
			payload: e,
		});

		window.navigator.vibrate(100);

		checkAnswer();
	};

	const checkAnswer = async () => {
		let state = store.getState().first;
		for (let index = 0; index < state.answers.length; index++) {
			let element = state.answers[index];
			// Looking for answer
			let ans;
			try {
				// eslint-disable-next-line
				ans = eval(state.input);
			} catch (error) {
				ans = state.input;
			}
			// eslint-disable-next-line
			if (ans == element) {
				// Clearing the input
				dispatch({
					type: "buttonClick",
					payload: "C",
				});

				// Deleting the Rock
				let rock = `rock${index}`;
				document.getElementById(rock).remove();

				beamSound.currentTime = 0;
				await beamSound.play();

				// Updating answer array
				dispatch({
					type: "answerArray",
					number: "X",
					index: index,
				});

				// Score Increment
				dispatch({
					type: "incrementScore",
					payload: 1,
				});

				break;
			}
		}
	};

	return (
		<div className="controller" id="controller">
			<span id="span">{state.input}</span>
			<div className="grid" id="numbers">
				<div className="row1">
					<button onTouchStart={handleButtonClicked}>1</button>
					<button onTouchStart={handleButtonClicked}>2</button>
					<button onTouchStart={handleButtonClicked}>3</button>
					<button onTouchStart={handleButtonClicked}>4</button>
					<button onTouchStart={handleButtonClicked}>5</button>
					<button onTouchStart={handleButtonClicked}>C</button>
				</div>

				<div className="row2">
					<button onTouchStart={handleButtonClicked}>6</button>
					{/* <button onTouchStart={handleButtonClicked}>+/-</button> */}
					<button onTouchStart={handleButtonClicked}>7</button>
					<button onTouchStart={handleButtonClicked}>8</button>
					<button onTouchStart={handleButtonClicked}>9</button>
					<button onTouchStart={handleButtonClicked}>0</button>
					{/* <button onTouchStart={handleButtonClicked}>.</button> */}
				</div>
			</div>
		</div>
	);
};

export default Controller;
