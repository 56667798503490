import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Importing Components
// import Navbar from './components/Navbar';
import Game from './components/Game';
import Home from './components/Home';
import Gameover from './components/Gameover';
import About from './components/About';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
import MagicSquare from './components/MagicSquare';

// Importing Styles
import './styles/App.scss';
import './styles/Game.scss';
import './styles/Rockfall.scss';
import './styles/Gameover.scss';
import './styles/Home.scss';
import './styles/Navbar.scss';
import './styles/About.scss';
import './styles/Contact.scss';
import './styles/MagicSquare.scss';


function App() {
  return (
    <>
      <Router>
          <Navbar />
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/about' element={<About />}></Route>
          <Route exact path='/contact' element={<><Navbar /><Contact /></>}></Route>
          <Route exact path='/play/start' element={<Game waveNum={1}/>}></Route>
          <Route exact path='/play/last' element={<Game waveNum={'l'}/>}></Route>
          <Route exact path='/play/custom' element={<Game waveNum={'c'}/>}></Route>
          <Route exact path='/gameover' element={<Gameover />}></Route>

          <Route exact path='/magicsquare/3' element={<MagicSquare n={3} />}></Route>
          <Route exact path='/magicsquare/4' element={<MagicSquare n={4} />}></Route>
        </Routes>
          
      </Router>
    </>
  );
}

export default App;
