import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
	function openNav() {
		document.getElementById("mySidenav").style.left = "0px";
	}
	function closeNav() {
		if (window.innerWidth < 900)
			if (window.innerWidth < 425) document.getElementById("mySidenav").style.left = "-100vw";
			else document.getElementById("mySidenav").style.left = "-300px";
	}
	return (
		<>
			<nav id="mySidenav" className="sidenav cursor">
				<div id="overnav"></div>
				<button className="closebtn" onClick={closeNav}>
					&times;
				</button>
				<Link onClick={closeNav} to="/">
					Home
				</Link>
				<Link onClick={closeNav} to="/about">
					About
				</Link>
				<Link onClick={closeNav} to="/contact">
					Contact Us
				</Link>
				<Link target="_blank" onClick={closeNav} to="https://www.termsandconditionsgenerator.com/live.php?token=x6MWNKMti6rS0QrhShrguxlQEOnhmzT0">
					Terms and Conditions
				</Link>
				<Link target="_blank" onClick={closeNav} to="https://www.privacypolicygenerator.info/live.php?token=wfNXrFW7ifDgCoYtdlRzaTWRC1NrNPCG">
					Privacy Policy
				</Link>
			</nav>
			<span className="span cursor" onClick={openNav}>
				&#9776;
			</span>
		</>
	);
};

export default Navbar;
