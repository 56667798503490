import React from "react";
import { Link } from "react-router-dom";
import store from "../redux/store";

const Home = () => {
	const { totalRocks } = store.getState().first;

	/*let timer;

	const rand = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	const createElements = (n) => {
		const nums = document.getElementById("floating-numbers");
		for (let i = 0; i < n; i++) {
			let elem = document.createElement("div");
			elem.innerHTML = i;
			nums.appendChild(elem);
			positionElement(elem);
		}
	};

	const positionElement = (elem) => {
		const nums = document.getElementById("floating-numbers").getBoundingClientRect();
		const elemD = elem.getBoundingClientRect();

		// getting random x-coordinate on screen
		const elemW = elemD.width;
		const xpos = Math.random() * (nums.width - elemW) + elemW / 2;

		// getting random y-coordinate on screen
		const elemH = elemD.height;
		const ypos = Math.random() * (nums.height - elemH) + elemH / 2;

		// positioning rock
		elem.style.left = `${xpos}px`;
		elem.style.top = `${ypos}px`;

		// Colouration
		let r = rand(0, 255);
		let g = rand(0, 255);
		let b = rand(0, 255);
		let rgb = `rgb(${r},${g},${b})`;
		elem.style.color = rgb;

		animate(elem, xpos, ypos);
	};

	const animate = (elem, xpos, ypos) => {
		const elemD = elem.getBoundingClientRect();
		let x = Math.round(rand(-1, 1));
		let y = Math.round(rand(-1, 1));
		if (x === 0) x = 1;
		if (y === 0) y = 1;
		try {
			timer = setInterval(() => {
				try {
					let nums = document.getElementById("floating-numbers").getBoundingClientRect();
					if (xpos <= 0) {
						x = 1;
					}
					if (ypos <= 0) {
						y = 1;
					}
					if (xpos >= nums.width - elemD.width) {
						x = -1;
					}
					if (ypos >= nums.height - elemD.height) {
						y = -1;
					}

					xpos += x;
					ypos += y;

					elem.style.left = `${xpos}px`;
					elem.style.top = `${ypos}px`;
				} catch (error) {
					console.log(error)
				}
			}, rand(10, 20));
		} catch (error) {
			console.log(error);
		}
	};

	const main = async () => {
		try {
			createElements(20);
		} catch (error) {}
	};

	useEffect(() => {
		// main();

		return () => {
			for (let i = timer; i >= 0; i--) {
				clearInterval(i);
			}
			// empty numbers
		};
	});*/

	return (
		<div className="home">
				<h1>Math Blaster Challenge</h1>
			<div className="articles">
				<article>
					<div className="button-box">
						<h3>Play From</h3>
						<div className="play-button">
							<Link to={"/play/start"}>Beginning</Link>
						</div>
						<div className="wave-button">
							<Link to={"/play/last"} id="last">
								Wave{" "}
								{Math.ceil((Number.parseInt(localStorage.getItem("hiscore")) + 1) / totalRocks) >= 50
									? 50
									: Math.ceil((Number.parseInt(localStorage.getItem("hiscore")) + 1) / totalRocks) || 1}
							</Link>
						</div>
					</div>
				</article>
				{/* <div id="new">New</div>
				<article>
					<div className="button-box">
						<h3>Magic Sqaure</h3>
						<div className="play-button">
							<Link to={"/magicsquare/3"}>3x3</Link>
						</div>
						<div className="wave-button">
							<Link to={"/magicsquare/4"} id="last">
								4x4
							</Link>
						</div>
					</div>
				</article> */}
			</div>
		</div>
	);
};

export default Home;
