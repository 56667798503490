import React from "react";
import { AiFillFacebook, AiFillInstagram, AiFillTwitterCircle, AiFillMail } from "react-icons/ai";

const Contact = () => {
	return (
		<>
			<div className="contact">
				<div className="form">
					<h1>Contact Us</h1>
					<form action="https://formsubmit.co/87c89d873ba61878dffd903fe7b6cd1f" method="POST">
						<label htmlFor="name">Name</label>
						<input type="text" name="name" id="name" placeholder="Enter Your Name..." required />
						<label htmlFor="email">Email</label>
						<input type="email" name="email" id="email" placeholder="Enter Your Email..." required />
						<label htmlFor="message">Message</label>
						<textarea name="message" id="message" cols="30" rows="10" placeholder="Enter Your Message..." required></textarea>
						<div id="buttons">
							<button type="reset">Reset</button>
							<button type="submit">Submit</button>
						</div>
					</form>
				</div>
				<article>
					<div>
						<a href="https://www.facebook.com/profile.php?id=61552713651865">
							<AiFillFacebook />
						</a>
					</div>
					<div>
						<a href="https://www.instagram.com/sanchaysachdev/">
							<AiFillInstagram />
						</a>
					</div>
					<div>
						<a href="mailto:sanchaysachdeva@mathblasterchallenge.com">
							<AiFillMail />
						</a>
					</div>
					<div>
						<a href="https://twitter.com/SanchaySachdev">
							<AiFillTwitterCircle />
						</a>
					</div>
				</article>
			</div>
		</>
	);
};

export default Contact;
