import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import congo from "../assets/congrats.mp3";

const Gameover = () => {
	const state = useSelector((state) => state.first);
	const totalRocks = state.totalRocks;
	const congrats = new Audio(congo);

	const showerConfetti = () => {
		const container = document.getElementById("container");

		for (let i = 0; i < 100; i++) {
			const confetti = document.createElement("div");
			confetti.classList.add("confetti");
			confetti.style.left = `${Math.random() * 100}%`;
			confetti.style.backgroundColor = `rgb(${rand(0, 255)},${rand(0, 255)},${rand(0, 255)})`;
			confetti.style.animationDuration = `${Math.random() * 2 + 1}s`;
			container.appendChild(confetti);
		}
	};

	const rand = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	const [content, setContent] = useState("Mathematics reminds us that every problem can have many solutions . . . . . and all of them can be wrong.");
	const [author, setAuthor] = useState("User00001");
	const [loading, setLoading] = useState(false);
	const [go, setGo] = useState('Game Over');

	async function generate() {
		try {
			setLoading(true);
			const response = await fetch("https://api.quotable.io/quotes/random?tags=motivational&maxLength=100");
			const data = await response.json();
			setContent(data[0].content);
			setAuthor(data[0].author);
			setLoading(false);
		} catch (error) {}
	}

	useEffect(() => {
		generate();
		console.log(state)
		if(state.hiscore){
			setGo('New Highscore')
			congrats.play();
			showerConfetti();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="endscreen">
				<div className="gameover">{go}</div>
				<div className="score">
					Highest Level : {parseInt(localStorage.getItem("hiscore")) >= 50 * totalRocks ? 50 * totalRocks - 1 + "+" : localStorage.getItem("hiscore")}
				</div>
				<div className="score">Level : {state.score >= 50 * totalRocks ? 50 * totalRocks - 1 + "+" : state.score}</div>
				<div className="jfq">
					{loading ? (
						<ClipLoader color="white" />
					) : (
						<>
							<p>{content}</p>
							<p>~ {author}</p>
						</>
					)}
				</div>
				<div className="lastProb">{state.lastQues === 0 ? "" : state.lastQues + " = " + state.lastAns}</div>
				<div className="box">
					<div className="back">
						<Link to={"/"}>Back to Home</Link>
					</div>
					<div className="play">
						<Link to={"/play/custom"}>Play Again</Link>
					</div>
				</div>
				<div className="container" id="container"></div>
			</div>
		</>
	);
};

export default Gameover;
