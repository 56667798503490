import React, { useEffect } from "react";
import Controller from "./Controller";
import { useDispatch } from "react-redux";
// import {useSelector } from "react-redux";
import store from "../redux/store";
import { useNavigate } from "react-router-dom";
import { problemGenerator } from "../scripts/ques";
import beam from "../assets/blaster.mp3";

const Game = (props) => {
	// Constants
	const dispatch = useDispatch();
	// const state = useSelector((state) => state.first);
	const totalRocks = store.getState().first.totalRocks;
	const velocity = 10000; // time taken by each rock to fall
	const navigate = useNavigate();
	const beamSound = new Audio(beam);

	// Variables
	let isGameOver = false;
	let waveNum = props.waveNum;
	let countRock = 0;
	let timer;
	let resolvePromise;

	if (waveNum === "l") {
		waveNum = Math.ceil((Number.parseInt(localStorage.getItem("hiscore")) + 1) / totalRocks) || 1;
	} else if (waveNum === "c") {
		waveNum = store.getState().first.waveNum;
	}

	if (waveNum > 50) waveNum = 50;

	// Functions

	const sleep = (time) => {
		return new Promise((resolve, reject) => {
			resolvePromise = resolve;
			timer = setTimeout(() => {
				resolve(true);
			}, time);
		});
	};

	const handleButtonClicked = (e) => {
		if ((e.key >= 0 && e.key <= 9) || e.key === "Backspace" || e.key === "Delete" || e.key === "-" || e.key === ".") {
			dispatch({
				type: "buttonClick",
				payload: e.key,
			});

			checkAnswer();
		}
	};

	const checkAnswer = async () => {
		let state = store.getState().first;
		for (let index = 0; index < state.answers.length; index++) {
			let element = state.answers[index];
			// Looking for answer
			let ans;
			try {
				// eslint-disable-next-line
				ans = eval(state.input);
			} catch (error) {
				ans = state.input;
			}
			// eslint-disable-next-line
			if (ans == element) {
				// Clearing the input
				dispatch({
					type: "buttonClick",
					payload: "C",
				});

				// Deleting the Rock
				let rock = `rock${index}`;
				document.getElementById(rock).remove();

				beamSound.currentTime = 0;
				await beamSound.play();

				// Updating answer array
				dispatch({
					type: "answerArray",
					number: "X",
					index: index,
				});

				// Score Increment
				dispatch({
					type: "incrementScore",
					payload: 1,
				});

				break;
			}
		}
	};

	const gameOver = async () => {
		try {
			isGameOver = true;

			// Clearing Sleep
			for (let i = timer; i >= 0; i--) {
				clearTimeout(i);
			}
			resolvePromise();

			checkHiscore();

			document.removeEventListener("keydown", handleButtonClicked);

			document.getElementById("mySidenav").style.opacity = "1";
		} catch (error) {
			console.log(error);
		}
	};

	const checkHiscore = () => {
		const { score } = store.getState().first;
		const hiscore = localStorage.getItem("hiscore");
		if (!hiscore) {
			localStorage.setItem("hiscore", 0);
		} else if (Number.parseInt(hiscore) < score) {
			dispatch({
				type: "hiscore",
			});
			localStorage.setItem("hiscore", score);
		}
	};

	const createRock = async () => {
		try {
			const rocks = document.getElementById("rocks");
			const rock = document.createElement("div");
			const data = problemGenerator(waveNum);

			// Updating Answer Array
			dispatch({
				type: "answerArray",
				number: parseFloat(data.ans),
				index: countRock,
			});

			rock.innerHTML = data.prob;
			rock.classList.add("rock");
			rock.id = `rock${countRock++}`;
			rock.style.fontSize = `${23 - rock.innerHTML.length}px`;

			rocks.insertAdjacentElement("afterbegin", rock);
		} catch (error) {
			console.log(error);
		}
	};

	const positionRock = () => {
		const rock = document.getElementsByClassName("rock")[0];
		const rockD = rock.getBoundingClientRect();
		const rocks = document.getElementById("rocks");

		// getting random x-coordinate on screen
		const rockWidth = rockD.width;
		const rockHeight = rockD.height;
		let xpos = Math.random() * (rocks.offsetWidth - rockWidth) + rockWidth / 2;

		// positioning rock
		// if(window.innerWidth>900) xpos+=(window.innerWidth-900)/2
		rock.style.left = `${xpos}px`;
		rock.style.top = `-${rockHeight}px`; // this will hide the rock
	};

	const fallRock = async () => {
		try {
			const rock = document.getElementsByClassName("rock")[0];
			const rockD = rock.getBoundingClientRect();

			const hr = document.getElementsByTagName("hr")[0].getBoundingClientRect().y;
			const ground = hr;

			const start = Date.now();
			const end = Date.now() + velocity;

			let dist;
			let v = 0;

			while (v < 1 && !isGameOver) {
				dist = v * ground - rockD.height;
				rock.style.top = `${dist}px`;
				v = (Date.now() - start) / (end - start);
				await sleep(10);
			}

			const state = store.getState().first;

			if (document.getElementById(rock.id) && state.lastQues === 0) {
				// Save Last Wrong Ans
				dispatch({
					type: "lastProb",
					ques: rock.innerText,
					ans: state.answers[rock.id.substring(4)],
				});
				gameOver();
				navigate("/gameover");
			}
		} catch (error) {
			console.log(error);
		}
	};

	const wave = async () => {
		try {
			countRock = 0;
			let interval = 5000;
			let delay = 200;
			for (let i = 0; i < totalRocks && !isGameOver; i++) {
				await createRock();
				positionRock();
				fallRock();

				interval -= delay;
				if (i < totalRocks - 1) await sleep(interval);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const waveStart = async (n) => {
		try {
			const text = document.getElementById("wave");
			text.innerText = `Wave ${n}`;
			text.classList.add("fade");
			await sleep(2000);
			text.classList.remove("fade");
		} catch (error) {
			console.log(error);
		}
	};

	const isWaveOver = async () => {
		try {
			let f = 0;
			let answers = store.getState().first.answers;
			do {
				await sleep(500);
				f = 0;
				answers = store.getState().first.answers;
				// eslint-disable-next-line
				answers.forEach((i) => {
					if (i !== "X") {
						f = 1;
					}
				});
			} while (f !== 0 && !isGameOver);
		} catch (error) {
			console.log(error);
		}
	};

	const main = async () => {
		try {
			// cleanups
			dispatch({
				type: "clear",
			});

			// updating score
			dispatch({
				type: "incrementScore",
				payload: (waveNum - 1) * totalRocks,
			});

			document.addEventListener("keydown", handleButtonClicked);

			document.getElementById("mySidenav").style.opacity = "0";

			while (!isGameOver) {
				dispatch({
					type: "waveChanger",
					payload: waveNum,
				});
				waveStart(waveNum);
				await sleep(2000);
				await wave();
				await isWaveOver();
				if (waveNum < 50) waveNum++;
			}
		} catch (error) {
			console.log(error);
		} finally {
			gameOver();
		}
	};

	useEffect(() => {
		main();

		return () => {
			gameOver();
		};
		// eslint-disable-next-line
	}, [isGameOver]);

	return (
		<>
			<div className="game">
				<div className="gameArea">
					<div id="rocks"></div>
					<div className="wave" id="wave"></div>
					<div className="text" id="score">
						{/* Level : {state.score} */}
					</div>
				</div>
				<hr />
				<Controller />
			</div>
		</>
	);
};

export default Game;
