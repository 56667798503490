import React from "react";

const About = () => {
	return (
		<div class="about">
			<header>
				<h1>
				<strong>About Us</strong>
				</h1>
			</header>
			<h2>
				Welcome to MathBlasterChallenge!
			</h2>
			<div>
				<span>
					MathBlasterChallenge is a fun and challenging Math game involving waves of problems on addition, subtraction, multiplication and division. We believe that gaining impressive calculative abilities is not a superhuman thing. Our mission is to decrease dependence on machines and bring out the potential of the human mind and to help people develop the ability to perform math calculations within seconds.
				</span>
			</div>
			<div>
				<span>This application was created after countless weeks of hard work and head scratching to provide everyone with a great user-experience. Kindly show us your love and support.
				</span>
			</div>
			<div>Thankyou for visiting out site!</div>
		</div>
	);
};

export default About;
