import { createAction, createReducer } from '@reduxjs/toolkit';

const buttonClick = createAction('buttonClick')
const answerArray = createAction('answerArray')
const incrementScore = createAction('incrementScore')
const lastProb = createAction('lastProb')
const waveChanger = createAction('waveChanger')
const clear = createAction('clear')
const hiscore = createAction('hiscore')

export const firstReducer = createReducer(
	{
		input: '',
		answers: [],
		score: 0,
		lastQues: 0,
		lastAns: 0,
		waveNum: 1,
		totalRocks: 20,
		hiscore: false
	},
	(builder) => {
		builder
			.addCase(buttonClick, (state, action) => {
				const ch = action.payload;
				if (ch === 'C' || ch === 'Backspace' || ch === 'Delete') {
					state.input = '';
				}
				else if (action.payload === '-') {
					if (state.input === '')
						state.input = '-';
					else if (state.input.charAt(0) === '-')
						state.input = state.input.substring(1);
					else
						state.input = '-' + state.input
				}
				else if (state.input.length < 10) {
					state.input += ch;
				}
			})
			.addCase(answerArray, (state, action) => {
				const num = action.number;
				const index = action.index;
				state.answers[index] = num;
			})
			.addCase(incrementScore, (state, action) => {
				state.score += action.payload;
			})
			.addCase(hiscore, (state, action) => {
				console.log('new hiscore')
				state.hiscore = true;
			})
			.addCase(lastProb, (state, action) => {
				state.lastQues = action.ques;
				state.lastAns = action.ans;
			})
			.addCase(waveChanger, (state, action) => {
				state.waveNum = action.payload;
			})
			.addCase(clear, (state, action) => {
				state.input = '';
				state.answers = [];
				state.score = 0;
				state.lastQues = 0;
				state.lastAns = 0;
				state.hiscore = false;
			})
	}
)


const updateMagicSum = createAction('updateMagicSum')

export const magicSquareReducer = createReducer(
	{
		magicSum: 0
	},
	(builder) => {
		builder
			.addCase(updateMagicSum, (state, action) => {
				state.magicSum = action.payload;
			})

	}
)