import { configureStore } from "@reduxjs/toolkit";
import { firstReducer, magicSquareReducer } from "./reducer";

const store=configureStore({
	reducer:{
		first: firstReducer,
		magicSquare: magicSquareReducer
	}
})

export default store;