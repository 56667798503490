
export const problemGenerator = (wave) => {
	const type = wave % 5;
	const level = Math.floor(wave / 5);
	const arr3 = [1];
	const arr2 = [1];
	let n1, n2, prob, ans, nums;
	switch (type) {
		case 1:
			nums = addSub(level);
			n1 = nums.num1;
			n2 = nums.num2;
			if (wave >= 26) {
				n1 = n1 / rand2(arr3);
				n2 = n2 / rand2(arr3);
			}
			prob = parseFloat(n1) + "+" + parseFloat(n2);
			ans = (n1 + n2).toFixed(2);
			break;
		case 2:
			nums = addSub(level);
			n1 = nums.num1;
			n2 = nums.num2;
			let max = Math.max(n1, n2)
			n2 = Math.min(n1, n2)
			n1=max;
			prob = parseFloat(n1) + "-" + parseFloat(n2);
			ans = (n1 - n2).toFixed(2);
			break;
		case 3:
			nums = proDiv(level + 1);
			n1 = nums.num1;
			n2 = nums.num2;
			if (wave >= 31) {
				n1 = n1 / rand2(arr2);
				n2 = n2 / rand2(arr2);
			}
			prob = parseFloat(n1) + '&times;' + parseFloat(n2);
			ans = (n1 * n2).toFixed(2)
			break;
		case 4:
			do {
				nums = proDiv(level + 1);
				n1 = nums.num1;
				n2 = nums.num2;
			} while (n2 === 0)
			if (wave >= 31) {
				n1 = n1 / rand2(arr2);
			}
			ans = n2
			prob = parseFloat((n1 * n2).toFixed(2)) + '&divide;' + parseFloat(n1);
			break;
		default:
			return problemGenerator(rand(wave - 4, wave - 1))
	}
	const qna = {
		prob: prob,
		ans: ans,
	}
	return qna;
}

const addSub = (level) => {
	const strength = Math.floor((level - 1) / 3)
	const type = level % 3;
	let n1, n2;
	switch (type) {
		case 1:
			n1 = 10 * Math.pow(10, strength);
			n2 = 20 * Math.pow(10, strength);
			break;
		case 2:
			n1 = 20 * Math.pow(10, strength);
			n2 = 50 * Math.pow(10, strength);
			break;
		default:
			n1 = 50 * Math.pow(10, strength);
			n2 = 100 * Math.pow(10, strength);
			if (level === 0) {
				n1 = 0;
				n2 = 10;
			}
			break;
	}
	const num1 = rand(n1, n2)
	const num2 = rand(n1, n2)
	const nums = { num1, num2 }
	// console.log(nums,level,strength,type)
	return nums;
}

const proDiv = (level) => {
	let num1, num2;
	if (level === 1) {
		num1 = rand(0, 10);
		num2 = rand(0, 10);
	}
	else if (level <= 5) {
		num1 = rand(1, 10);
		num2 = rand((level - 2) * 20, (level - 1) * 20)
	}
	else {
		num1 = rand(10, 20)
		level -= 4;
		num2 = rand((level - 2) * 20, (level - 1) * 20)
	}
	const nums = { num1, num2 }
	// console.log(nums,level)
	// console.log('n1',n1)
	// console.log('n2',n2)
	return nums;
}

const rand = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

const rand2 = (arr) => {
	return arr[rand(0, arr.length - 1)]
}