import React, { useEffect, useRef, useState } from "react";
import congo from "../assets/congrats.mp3";
import clicky from "../assets/click.mp3";
import { useNavigate } from "react-router-dom";

const MagicSquare = (props) => {
	const [clickCount, setClickCount] = useState(0);
	const [click, setClick] = useState();
	const [firstClick, setFirstClick] = useState();
	const [secondClick, setSecondClick] = useState();
	const [magicSum, setMagicSum] = useState(0);
	const [time, setTime] = useState(0);
	const [timer, setTimer] = useState();

	const minutes = Math.floor(time / 60);
	const seconds = Math.floor(time % 60);

	const congrats = new Audio(congo);
	const clickSound = new Audio(clicky);

	const naviagte = useNavigate();

	const n = props.n + 2;

	const handleBackToHome = () => {
		naviagte("/");
	};

	function generate3x3MagicSquare() {
		const arr = [
			[0, 0, 0],
			[0, 0, 0],
			[0, 0, 0],
		];

		let n = 3;
		let row = 0;
		let col = Math.floor(n / 2);
		let num = 1;
		let r = rand(0, 10);
		setMagicSum(15 + 3 * r);

		while (num <= n * n) {
			arr[row][col] = num + r;
			num++;

			const newRow = (row - 1 + n) % n;
			const newCol = (col + 1) % n;

			if (arr[newRow][newCol] !== 0) {
				row = (row + 1) % n;
			} else {
				row = newRow;
				col = newCol;
			}
		}

		const N = 5;
		const magicSquare = new Array(5).fill().map(() => new Array(5).fill(0));

		for (let i = 1; i < N - 1; i++) {
			for (let j = 1; j < N - 1; j++) {
				magicSquare[i][j] = arr[i - 1][j - 1];
			}
		}

		return updateSum(shuffleNonBorderElements(magicSquare));
	}

	const generateMagicSqaure = () => {
		const magicSquare = new Array(n);
		for (let i = 0; i < n; i++) {
			magicSquare[i] = new Array(n).fill(0);
		}

		const x = 3;
		const y = 10;

		const A = rand(x, y);
		const B = rand(x, y);
		const C = rand(x, y);
		const D = rand(x, y);

		setMagicSum(() => A + B + C + D);

		// Row 1
		magicSquare[1][1] = A;
		magicSquare[1][2] = B;
		magicSquare[1][3] = C;
		magicSquare[1][4] = D;

		// Row 2
		magicSquare[2][1] = D + 1;
		magicSquare[2][2] = C - 1;
		magicSquare[2][3] = B - 3;
		magicSquare[2][4] = A + 3;

		// Row 3
		magicSquare[3][1] = B - 2;
		magicSquare[3][2] = A + 2;
		magicSquare[3][3] = D + 2;
		magicSquare[3][4] = C - 2;

		// Row 4
		magicSquare[4][1] = C + 1;
		magicSquare[4][2] = D - 1;
		magicSquare[4][3] = A + 1;
		magicSquare[4][4] = B - 1;

		return updateSum(shuffleNonBorderElements(magicSquare));
	};
	const rand = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};
	const updateSum = (arr) => {
		let sum = 0;
		for (let i = 0; i < n; i++) {
			for (let j = 0; j < n; j++) {
				if ((i === 0 && j === 0) || (i === n - 1 && j === n - 1)) {
					for (let k = 1; k <= n - 2; k++) {
						sum += arr[k][k];
					}
					arr[i][j] = sum;
					sum = 0;
				} else if ((i === 0 && j === n - 1) || (i === n - 1 && j === 0)) {
					for (let k = 1; k <= n - 2; k++) {
						sum += arr[n - 1 - k][k];
					}
					arr[i][j] = sum;
					sum = 0;
				} else if (i === 0 || i === n - 1) {
					for (let k = 1; k <= n - 2; k++) {
						sum += arr[k][j];
					}
					arr[i][j] = sum;
					sum = 0;
				} else if (j === 0 || j === n - 1) {
					for (let k = 1; k <= n - 2; k++) {
						sum += arr[i][k];
					}
					arr[i][j] = sum;
					sum = 0;
				}
			}
		}
		return arr;
	};

	// Function to shuffle an array
	function shuffleArray(arr) {
		for (let i = arr.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[arr[i], arr[j]] = [arr[j], arr[i]]; // Swap elements at i and j
		}
	}

	// Function to shuffle non-border elements of a 2D array
	function shuffleNonBorderElements(arr) {
		const numRows = arr.length;
		const numCols = arr[0].length;

		// Create an array of inner elements (excluding the border)
		const innerElements = [];
		for (let i = 1; i < numRows - 1; i++) {
			for (let j = 1; j < numCols - 1; j++) {
				innerElements.push(arr[i][j]);
			}
		}

		// Shuffle the inner elements
		shuffleArray(innerElements);

		// Insert shuffled inner elements back into the 2D array
		let index = 0;
		for (let i = 1; i < numRows - 1; i++) {
			for (let j = 1; j < numCols - 1; j++) {
				arr[i][j] = innerElements[index];
				index++;
			}
		}
		return arr;
	}

	const gridArray = useRef();
	if (!gridArray.current) {
		gridArray.current = n === 6 ? generateMagicSqaure() : generate3x3MagicSquare();
	}

	const showerConfetti = () => {
		const container = document.getElementById("container");

		for (let i = 0; i < 100; i++) {
			const confetti = document.createElement("div");
			confetti.classList.add("confetti");
			confetti.style.left = `${Math.random() * 100}%`;
			confetti.style.backgroundColor = `rgb(${rand(0, 255)},${rand(0, 255)},${rand(0, 255)})`;
			confetti.style.animationDuration = `${Math.random() * 2 + 1}s`;
			container.appendChild(confetti);
		}
	};

	useEffect(() => {
		if (clickCount === 1) {
			setFirstClick(click);
			handleAddClickStyle(click);
		} else if (clickCount === 2) {
			setSecondClick(click);
			handleRemoveClickStyle(firstClick);
			// Swapping
			if (secondClick) {
				clickSound.currentTime = 0;
				clickSound.play();
				handleUpdateArray(firstClick, secondClick);
				if (gridIsCorrect()) {
					clearInterval(timer);
					showerConfetti();
					congrats.play();
					// new game
				}
				setClickCount(0);
				setFirstClick();
				setSecondClick();
			}
		}
		handleColorUpdate();
		// eslint-disable-next-line
	}, [firstClick, clickCount, secondClick, magicSum]);

	const gridIsCorrect = () => {
		for (let i = 0; i < n; i++) {
			for (let j = 0; j < n; j++) {
				if (i === 0 || j === 0 || i === n - 1 || j === n - 1)
					if (gridArray.current[i][j] !== magicSum) {
						return false;
					}
			}
		}
		return true;
	};

	const handleUpdateArray = (first, second) => {
		const i1 = first.id.charAt(0);
		const j1 = first.id.charAt(1);
		const i2 = second.id.charAt(0);
		const j2 = second.id.charAt(1);

		const x = gridArray.current[i1][j1];
		gridArray.current[i1][j1] = gridArray.current[i2][j2];
		gridArray.current[i2][j2] = x;

		gridArray.current = updateSum(gridArray.current);
	};

	const updateColor = (elem, sum) => {
		if (elem.textContent === sum.toString()) {
			elem.style.backgroundColor = "rgba(0,255,0,0.7)";
		} else {
			elem.style.backgroundColor = "rgba(255,0,0,0.9)";
		}
	};

	const handleColorUpdate = () => {
		Array.from(document.getElementsByClassName("border")).forEach((elem, index) => {
			updateColor(elem, magicSum);
		});
	};

	useEffect(() => {
		Array.from(document.getElementsByClassName("non-border")).forEach((elem, index) => {
			elem.addEventListener("click", SquareClicked);
		});
		handleColorUpdate();
		handleTimer();

		// eslint-disable-next-line
	}, []);

	const handleAddClickStyle = (elem) => {
		elem.style.color = "orange";
		elem.style.border = "2px solid yellow";
		elem.style.backgroundColor = "blue";
	};
	const handleRemoveClickStyle = (elem) => {
		elem.style.color = "white";
		elem.style.border = "transparent";
		elem.style.backgroundColor = "rgba(255, 166, 0, 0.705)";
	};

	const SquareClicked = (e) => {
		setClickCount((previous) => previous + 1);
		// handleClickStyle(e.target)
		setClick(e.target);
	};

	const handleTimer = () => {
		// starting time
		const interval = setInterval(() => {
			setTime((previous) => previous + 1);
		}, 1000);
		setTimer(interval);
	};

	const showPopUp = (q) => {
		q = q.target.id.charAt(1);
		if (q === "1") {
			toggleDisplay(document.getElementById("defination"));
		} else if (q === "2") {
			toggleDisplay(document.getElementById("method"));
		}
	};

	const toggleDisplay = (elem) => {
		if (elem.style.display === "") elem.style.display = "block";
		else elem.style.display = "";
	};

	const handleNewGame = () => {
		document.getElementById("container").innerHTML = "";
		clearInterval(timer);
		setTime(0);
		setClickCount(0);
		handleTimer();
		setClick();
		if (firstClick) handleRemoveClickStyle(firstClick);
		setFirstClick();
		setSecondClick();
		setMagicSum(0);
		gridArray.current = n === 6 ? generateMagicSqaure() : generate3x3MagicSquare();
	};

	return (
		<div className="magicsquaregame">
			<h1>Magic Square</h1>

			<div>
				<h2 id="q1" onClick={showPopUp}>
					What is Magic Square?
				</h2>
				<div id="defination">
					A Magic Square is defined as a square containing several numbers arranged such that the sum of the numbers is the same in every row, column,
					and diagonal.
				</div>
			</div>

			<div>
				<h2 id="q2" onClick={showPopUp}>
					How to Win?
				</h2>
				<div id="method">
					You can swap any two numbers in the inner square by clicking on the respective squares one after another. Create a Magic Square by swapping
					the numbers. <br />
					The outer numbers in the outer sqaures display the sum of each row, column, and diagonal. In a Magic Square, all the number in the outer
					square are same and equal to the Magic Sum.
				</div>
			</div>
			<div className="magicsquare">
				<div className="grid">
					<table>
						<tbody>
							{gridArray.current.map((row, rowIndex) => (
								<tr key={rowIndex}>
									{row.map((cell, cellIndex) => (
										<td
											id={rowIndex + "" + cellIndex}
											className={cellIndex === 0 || rowIndex === 0 || cellIndex === n - 1 || rowIndex === n - 1 ? "border" : "non-border"}
											key={cellIndex}
										>
											{cell}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<div className="others">
				<div className="time">
					{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
				</div>
				<div className="sum">Magic Sum : {magicSum}</div>
			</div>
			<div className="buttonGroup">
				<button onClick={handleBackToHome}>Home</button>
				<button onClick={handleNewGame}>New Game</button>
			</div>
			<div className="container" id="container"></div>
		</div>
	);
};

export default MagicSquare;
